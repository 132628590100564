<script setup lang="ts">
import {ref, computed} from 'vue';
import {faChevronLeft, faChevronDown} from '@fortawesome/pro-light-svg-icons';
import {store} from '../services';

defineProps<{
  direction: 'column' | 'row';
}>();

type MenuSegment = {
  title: string;
  url?: string;
  items: {
    title: string;
    url: string;
  }[];
};

type MenuItem = {
  title: string;
  segments?: MenuSegment[];
  url?: string;
  submenu: {
    title: string;
    items: {
      text: string;
      link: string;
    }[];
  }[];
};

const menuItems = computed(() => {
  const menuItems = store.state.subdomain?.mainMenu;

  if (!menuItems || !menuItems.length) {
    return [];
  }

  return menuItems.map(
    (item: {
      text: string;
      href: string;
      submenu: {
        title: string;
        items: {
          text: string;
          link: string;
        }[];
      }[];
    }) => {
      return {
        title: item.text,
        url: item.href,
        segments: item.submenu?.map(segment => {
          return {
            title: segment.title,
            items: segment.items.map(item => {
              return {
                title: item.text,
                url: item.link,
              };
            }),
          };
        }),
      };
    }
  );
});

const activeMenuItem = ref<MenuItem>();
const activeMenuSegment = ref<MenuSegment>();

const exitTimeout = ref<ReturnType<typeof setTimeout>>();

function onMenuItemMouseLeave() {
  if (!activeMenuItem.value?.segments?.length) {
    activeMenuItem.value = undefined;
    return;
  }

  exitTimeout.value = setTimeout(() => {
    activeMenuItem.value = undefined;
  }, 100);
}

function onMenuItemMouseEnter(item: MenuItem) {
  activeMenuItem.value = item;
  clearTimeout(exitTimeout.value);
}

function onNestedMenuMouseEnter() {
  clearTimeout(exitTimeout.value);
}

function onMobileMenuItemClick(item: MenuItem) {
  if (item.segments?.length) {
    activeMenuItem.value = item;
  }
}

function onMobileMenuSegmentClick(segment: MenuSegment) {
  activeMenuSegment.value = segment;
}
</script>

<template>
  <div
    class="flex flex-wrap gap-x-4 gap-y-3 ml-4"
    :style="{
      flexDirection: direction,
    }"
  >
    <template v-if="direction === 'row'">
      <tml-anchor
        v-for="(item, index) in menuItems"
        :key="index"
        class="shrink-0 !text-[--tml-menu-text-color]"
        :text="item.title"
        :style="{
          fontFamily: 'var(--tml-menu-text-font) !important',
          textTransform: 'var(--tml-menu-text-transform) !important',
        }"
        :href="item.url || '#'"
        @mouseover="onMenuItemMouseEnter(item)"
        @mouseleave="onMenuItemMouseLeave"
      >
        <p class="!text-[--tml-menu-text-color]">
          {{ item.title }}
          <font-awesome-icon
            v-if="item.segments?.length"
            class="ml-1 text-sm"
            :icon="faChevronDown"
          />
        </p>
      </tml-anchor>
    </template>
    <transition-group v-else name="slide-right">
      <template v-if="!activeMenuItem">
        <tml-anchor
          v-for="(item, index) in menuItems"
          :key="index"
          class="shrink-0 block !text-[--tml-menu-text-color]"
          :href="item.segments?.length ? '#' : item.url"
          :text="item.title"
          :style="{
            fontFamily: 'var(--tml-menu-text-font) !important',
          }"
          @click="onMobileMenuItemClick(item)"
        >
          <h4 class="mb-2 !text-[--tml-menu-text-color]">
            {{ item.title }}
            <font-awesome-icon
              v-if="item.segments?.length"
              class="mr-2 rotate-180 float-right pb-1.5"
              :icon="faChevronLeft"
            />
          </h4>
        </tml-anchor>
      </template>
      <template v-else-if="activeMenuItem && !activeMenuSegment">
        <tml-anchor
          class="text-sm font-bold mb-4 block"
          @click="activeMenuItem = undefined"
        >
          <p class="font-bold mb-2 !text-[--tml-form-text-secondary-color]">
            <font-awesome-icon class="mr-2" :icon="faChevronLeft" /> All
          </p>
        </tml-anchor>
        <tml-anchor :href="activeMenuItem.url || '#'">
          <h4 class="mb-4 !text-[--tml-menu-text-color]">
            {{ activeMenuItem.title }}
          </h4>
        </tml-anchor>
        <div
          v-for="(segment, segmentIndex) in activeMenuItem.segments"
          :key="segmentIndex"
        >
          <tml-anchor
            text="Link"
            href="#"
            @click="onMobileMenuSegmentClick(segment)"
          >
            <h5 class="font-bold mb-2 !text-[--tml-menu-text-color]">
              {{ segment.title }}
              <font-awesome-icon
                class="mr-2 rotate-180 float-right pb-1.5"
                :icon="faChevronLeft"
              />
            </h5>
          </tml-anchor>
        </div>
      </template>
      <template v-else-if="activeMenuSegment">
        <tml-anchor
          class="text-sm font-bold mb-4 block"
          @click="activeMenuSegment = undefined"
        >
          <p class="font-bold mb-2 !text-[--tml-form-text-secondary-color]">
            <font-awesome-icon class="mr-2" :icon="faChevronLeft" />
            {{ activeMenuItem.title }}
          </p>
        </tml-anchor>
        <tml-anchor :href="activeMenuSegment.url || '#'">
          <h4 class="mb-4 !text-[--tml-menu-text-color]">
            {{ activeMenuSegment.title }}
          </h4>
        </tml-anchor>
        <tml-anchor
          v-for="(item, itemIndex) in activeMenuSegment.items"
          :key="itemIndex"
          class="shrink-0 block !text-[--tml-menu-text-color]"
          :text="item.title"
          :href="item.url"
          :style="{
            fontFamily: 'var(--tml-menu-text-font) !important',
          }"
        >
          <p class="mb-2 !text-[--tml-menu-text-color]">
            {{ item.title }}
          </p>
        </tml-anchor>
      </template>
    </transition-group>
  </div>
  <template v-if="direction === 'row'">
    <transition name="open-down">
      <div
        v-if="activeMenuItem?.segments?.length"
        class="nested-menu w-full absolute left-0 right-0 top-[100%] bg-[--tml-menu-background-color] z-20 p-8 border-t overflow-y-auto"
        @mouseover="onNestedMenuMouseEnter"
      >
        <div class="container">
          <tml-grid :breakpoints="{xs: activeMenuItem.segments.length}">
            <div
              v-for="(segment, segmentIndex) in activeMenuItem.segments"
              :key="segmentIndex"
            >
              <h4 class="font-bold mb-2 !text-[--tml-menu-text-color]">
                {{ segment.title }}
              </h4>
              <tml-anchor
                v-for="(item, itemIndex) in segment.items"
                :key="itemIndex"
                :href="item.url"
                @click="activeMenuItem = undefined"
              >
                <p class="mb-2 text-sm !text-[--tml-menu-text-color]">
                  {{ item.title }}
                </p>
              </tml-anchor>
            </div>
          </tml-grid>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="activeMenuItem?.segments?.length"
        class="fixed left-0 right-0 top-[100%] bg-black/25 z-10 h-screen"
        @mouseover="activeMenuItem = undefined"
      />
    </transition>
  </template>
</template>

<style scoped lang="scss">
.nested-menu {
  max-height: calc(100vh - 100px);
}
.slide-right-enter-active {
  transition: all 0.4s ease;
}
.slide-right-leave-active {
}
.slide-right-enter-from {
  transform: translateX(20px);
  opacity: 0;
}
.slide-right-leave-to {
  height: 0;
}
</style>
