import {Font} from '../../classes/font';

export const fonts = [
  new Font({name: 'Acumin Pro', type: 'display', defaultWeight: 300}),
  new Font({name: 'Abril Fatface', type: 'display'}),
  new Font({name: 'Amiri', type: 'display'}),
  new Font({name: 'Alex Brush', type: 'display'}),
  new Font({name: 'Alfa Slab One', type: 'display'}),
  new Font({name: 'Aladin', type: 'display'}),
  new Font({name: 'Amatic SC', type: 'display'}),
  new Font({name: 'Anton', type: 'display'}),
  new Font({name: 'Archivo Black', type: 'display'}),
  new Font({name: 'Architects Daughter', type: 'display'}),
  new Font({name: 'Arimo', type: 'display'}),
  new Font({name: 'Atkinson Hyperlegible', type: 'display'}),
  new Font({name: 'Audiowide', type: 'display'}),
  new Font({name: 'Averia Libre', type: 'display'}),
  new Font({name: 'Bahiana', type: 'display'}),
  new Font({name: 'Balsamiq Sans', type: 'display'}),
  new Font({name: 'Balthazar', type: 'display'}),
  new Font({name: 'Bangers', type: 'display'}),
  new Font({name: 'Barlow Condensed', type: 'display', defaultWeight: 300}),
  new Font({name: 'Barrio', type: 'display'}),
  new Font({name: 'Bebas Neue', type: 'display'}),
  new Font({name: 'Berkshire Swash', type: 'display'}),
  new Font({name: 'Beth Ellen', type: 'display'}),
  new Font({name: 'Bowlby One SC', type: 'display'}),
  new Font({name: 'Bubblegum Sans', type: 'display'}),
  new Font({name: 'Bungee', type: 'display'}),
  new Font({name: 'Caesar Dressing', type: 'display'}),
  new Font({name: 'Calistoga', type: 'display'}),
  new Font({name: 'Carter One', type: 'display'}),
  new Font({name: 'Catamaran', type: 'display', defaultWeight: 900}),
  new Font({name: 'Caveat', type: 'display'}),
  new Font({name: 'Caveat Brush', type: 'display'}),
  new Font({name: 'Chelsea Market', type: 'display'}),
  new Font({name: 'Chewy', type: 'display'}),
  new Font({name: 'Chela One', type: 'display'}),
  new Font({name: 'Cinzel', type: 'display', defaultWeight: 700}),
  new Font({name: 'Coustard', type: 'display'}),
  new Font({name: 'Condiment', type: 'display'}),
  new Font({name: 'Corben', type: 'display'}),
  new Font({name: 'Courier Prime', type: 'display'}),
  new Font({name: 'Covered By Your Grace', type: 'display'}),
  new Font({name: 'Chicle', type: 'display'}),
  new Font({name: 'Creepster', type: 'display'}),
  new Font({name: 'DM Serif Text', type: 'display'}),
  new Font({name: 'DM Serif Display', type: 'display'}),
  new Font({name: 'Dokdo', type: 'display'}),
  new Font({name: 'Dongle', type: 'display'}),
  new Font({name: 'Dorsa', type: 'display'}),
  new Font({name: 'Dancing Script', type: 'display'}),
  new Font({name: 'East Sea Dokdo', type: 'display'}),
  new Font({name: 'Erica One', type: 'display'}),
  new Font({name: 'Figtree', type: 'display'}),
  new Font({name: 'Fjalla One', type: 'display'}),
  new Font({name: 'Flavors', type: 'display'}),
  new Font({name: 'Fredoka One', type: 'display'}),
  new Font({name: 'Freckle Face', type: 'display'}),
  new Font({name: 'Fugaz One', type: 'display'}),
  new Font({name: 'Gaegu', type: 'display'}),
  new Font({name: 'Germania One', type: 'display'}),
  new Font({name: 'Gluten', type: 'display'}),
  new Font({name: 'Graduate', type: 'display'}),
  new Font({name: 'Grand Hotel', type: 'display'}),
  new Font({name: 'Grandstander', type: 'display'}),
  new Font({name: 'Heebo', type: 'display', defaultWeight: 700}),
  new Font({name: 'Homemade Apple', type: 'display'}),
  new Font({name: 'Italiana', type: 'display'}),
  new Font({name: 'IBM Plex Sans', type: 'display'}),
  new Font({name: 'Jolly Lodger', type: 'display'}),
  new Font({name: 'Jost', type: 'display'}),
  new Font({name: 'Julius Sans One', type: 'display'}),
  new Font({name: 'Kalam', type: 'display'}),
  new Font({name: 'Kanit', type: 'display'}),
  new Font({name: 'Knewave', type: 'display'}),
  new Font({name: 'Koulen', type: 'display'}),
  new Font({name: 'Kumbh Sans', type: 'display'}),
  new Font({name: 'La Belle Aurore', type: 'display'}),
  new Font({name: 'Lacquer', type: 'display'}),
  new Font({name: 'League Gothic', type: 'display'}),
  new Font({name: 'League Spartan', type: 'display'}),
  new Font({name: 'Leckerli One', type: 'display'}),
  new Font({name: 'Lobster', type: 'display'}),
  new Font({name: 'Londrina Sketch', type: 'display'}),
  new Font({name: 'Lilita One', type: 'display'}),
  new Font({name: 'Londrina Solid', type: 'display'}),
  new Font({name: 'Luckiest Guy', type: 'display'}),
  new Font({name: 'Mansalva', type: 'display'}),
  new Font({name: 'MedievalSharp', type: 'display'}),
  new Font({name: 'Metamorphous', type: 'display'}),
  new Font({name: 'Mitr', type: 'display'}),
  new Font({name: 'Modak', type: 'display'}),
  new Font({name: 'Molle', type: 'display'}),
  new Font({name: 'Monoton', type: 'display'}),
  new Font({name: 'Mr Bedfort', type: 'display'}),
  new Font({name: 'Mr Dafoe', type: 'display'}),
  new Font({name: 'Mrs Sheppards', type: 'display'}),
  new Font({name: 'Nanum Brush Script', type: 'display'}),
  new Font({name: 'Nanum Pen Script', type: 'display'}),
  new Font({name: 'Nerko One', type: 'display'}),
  new Font({name: 'Neuton', type: 'display', defaultWeight: 700}),
  new Font({name: 'Niconne', type: 'display'}),
  new Font({name: 'Nixie One', type: 'display'}),
  new Font({name: 'Notable', type: 'display'}),
  new Font({name: 'Nosifer', type: 'display'}),
  new Font({name: 'Odibee Sans', type: 'display'}),
  new Font({name: 'Original Surfer', type: 'display'}),
  new Font({name: 'Outfit', type: 'display'}),
  new Font({name: 'Pacifico', type: 'display'}),
  new Font({name: 'Palanquin', type: 'display', defaultWeight: 300}),
  new Font({name: 'Passion One', type: 'display'}),
  new Font({name: 'Passero One', type: 'display'}),
  new Font({name: 'Pattaya', type: 'display'}),
  new Font({name: 'Patua One', type: 'display'}),
  new Font({name: 'Patrick Hand', type: 'display'}),
  new Font({name: 'Paytone One', type: 'display'}),
  new Font({name: 'Permanent Marker', type: 'display'}),
  new Font({name: 'Pirata One', type: 'display'}),
  new Font({name: 'Playfair Display', type: 'display'}),
  new Font({name: 'Poppins', type: 'display', defaultWeight: 700}),
  new Font({name: 'Poiret One', type: 'display'}),
  new Font({name: 'Prata', type: 'display'}),
  new Font({name: 'Press Start 2P', type: 'display'}),
  new Font({name: 'Purple Purse', type: 'display'}),
  new Font({name: 'Qwigley', type: 'display'}),
  new Font({name: 'Radio Canada', type: 'display'}),
  new Font({name: 'Rambla', type: 'display'}),
  new Font({name: 'Ranchers', type: 'display'}),
  new Font({name: 'Red Rose', type: 'display'}),
  new Font({name: 'Reem Kufi', type: 'display'}),
  new Font({name: 'Rock Salt', type: 'display'}),
  new Font({name: 'Rozha One', type: 'display'}),
  new Font({name: 'Rubik Mono One', type: 'display'}),
  new Font({name: 'Special Elite', type: 'display'}),
  new Font({name: 'Spicy Rice', type: 'display'}),
  new Font({name: 'Sintony', type: 'display'}),
  new Font({name: 'Six Caps', type: 'display'}),
  new Font({name: 'Shrikhand', type: 'display'}),
  new Font({name: 'Skranji', type: 'display'}),
  new Font({name: 'Sniglet', type: 'display', defaultWeight: 800}),
  new Font({name: 'Squada One', type: 'display'}),
  new Font({name: 'Staatliches', type: 'display'}),
  new Font({name: 'Stylish', type: 'display'}),
  new Font({name: 'Sedgwick Ave Display', type: 'display'}),
  new Font({name: 'Suranna', type: 'display'}),
  new Font({name: 'Teko', type: 'display'}),
  new Font({name: 'Thasadith', type: 'display'}),
  new Font({name: 'Yeseva One', type: 'display'}),
  new Font({name: 'Ubuntu', type: 'display', defaultWeight: 700}),
  new Font({name: 'Ultra', type: 'display'}),
  new Font({name: 'Uncial Antiqua', type: 'display'}),
  new Font({name: 'UnifrakturMaguntia', type: 'display'}),
  new Font({name: 'Varela', type: 'display'}),
  new Font({name: 'Vibur', type: 'display'}),
  new Font({name: 'Vollkorn', type: 'display'}),
  new Font({name: 'VT323', type: 'display'}),
  new Font({name: 'Work Sans', type: 'display'}),
  new Font({name: 'Yellowtail', type: 'display'}),
  new Font({name: 'ZCOOL KuaiLe', type: 'display'}),
  new Font({name: 'Exo 2', type: 'display'}),
  new Font({name: 'Aboreto', type: 'display'}),
  new Font({name: 'Bevan', type: 'display'}),
  new Font({name: 'Boogaloo', type: 'display'}),
  new Font({name: 'Bungee Hairline', type: 'display'}),
  new Font({name: 'Changa One', type: 'display'}),
  new Font({name: 'Climate Crisis', type: 'display'}),
  new Font({name: 'Crushed', type: 'display'}),
  new Font({name: 'Dela Gothic One', type: 'display'}),
  new Font({name: 'Damion', type: 'display'}),
  new Font({name: 'Diplomata', type: 'display'}),
  new Font({name: 'DynaPuff', type: 'display'}),
  new Font({name: 'Emblema One', type: 'display'}),
  new Font({name: 'Fascinate', type: 'display'}),
  new Font({name: 'Fascinate Inline', type: 'display'}),
  new Font({name: 'Goblin One', type: 'display'}),
  new Font({name: 'Gorditas', type: 'display'}),
  new Font({name: 'Irish Grover', type: 'display'}),
  new Font({name: 'Jua', type: 'display'}),
  new Font({name: 'Limelight', type: 'display'}),
  new Font({name: 'Metal Mania', type: 'display'}),
  new Font({name: 'Miltonian', type: 'display'}),
  new Font({name: 'Miltonian Tattoo', type: 'display'}),
  new Font({name: 'Moo Lah Lah', type: 'display'}),
  new Font({name: 'Mouse Memoirs', type: 'display'}),
  new Font({name: 'Neonderthaw', type: 'display'}),
  new Font({name: 'Oi', type: 'display'}),
  new Font({name: 'Overlock', type: 'display'}),
  new Font({name: 'Peralta', type: 'display'}),
  new Font({name: 'Playball', type: 'display'}),
  new Font({name: 'Poller One', type: 'display'}),
  new Font({name: 'Rammetto One', type: 'display'}),
  new Font({name: 'Racing Sans One', type: 'display'}),
  new Font({name: 'Rampart One', type: 'display'}),
  new Font({name: 'Ribeye Marrow', type: 'display'}),
  new Font({name: 'Righteous', type: 'display'}),
  new Font({name: 'Rye', type: 'display'}),
  new Font({name: 'Rubik Dirt', type: 'display'}),
  new Font({name: 'Sacramento', type: 'display'}),
  new Font({name: 'Smokum', type: 'display'}),
  new Font({name: 'Style Script', type: 'display'}),
  new Font({name: 'Segoe UI', type: 'display'}),
  new Font({name: 'Varela Round', type: 'display'}),
  new Font({name: 'Vast Shadow', type: 'display'}),

  new Font({name: 'Lato', type: ['standard', 'display']}),
  new Font({name: 'Lexend', type: ['standard', 'display']}),
  new Font({name: 'Questrial', type: ['standard', 'display']}),

  new Font({name: 'Libre Baskerville', type: 'standard'}),
  new Font({name: 'Montserrat', type: 'standard'}),
  new Font({name: 'Mulish', type: 'standard'}),
  new Font({name: 'Nunito', type: 'standard'}),
  new Font({name: 'Open Sans', type: 'standard'}),
  new Font({name: 'Roboto', type: 'standard'}),
  new Font({name: 'Raleway', type: 'standard'}),
  new Font({name: 'Roboto Condensed', type: 'standard'}),
  new Font({name: 'Sora', type: 'standard'}),
  new Font({name: 'Oswald', type: 'standard'}),
  new Font({name: 'Josefin Sans', type: 'standard'}),
  new Font({name: 'Roboto Slab', type: 'standard'}),
  new Font({name: 'Lora', type: 'standard'}),
  new Font({name: 'Spectral', type: 'standard'}),
  new Font({name: 'Source Serif Pro', type: 'standard'}),
  new Font({name: 'Source Sans Pro', type: 'standard'}),
  new Font({name: 'Comfortaa', type: 'standard'}),
  new Font({name: 'Dekko', type: 'standard'}),
  new Font({
    name: 'Pecita',
    type: 'display',
    source: 'custom',
    url: 'https://fonts.cdnfonts.com/css/pecita',
  }),
  new Font({
    name: 'Tropico',
    type: 'display',
    source: 'custom',
    url: 'https://tml-production-bucket.s3.eu-west-1.amazonaws.com/fonts/tropico.css',
  }),
  new Font({
    name: 'Cooper Black',
    type: 'display',
    source: 'custom',
    url: 'https://tml-production-bucket.s3.eu-west-1.amazonaws.com/fonts/cooper-black.css',
  }),
].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
